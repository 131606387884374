// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-account-js": () => import("./../../../src/pages/account.js" /* webpackChunkName: "component---src-pages-account-js" */),
  "component---src-pages-brands-js": () => import("./../../../src/pages/brands.js" /* webpackChunkName: "component---src-pages-brands-js" */),
  "component---src-pages-cart-js": () => import("./../../../src/pages/cart.js" /* webpackChunkName: "component---src-pages-cart-js" */),
  "component---src-pages-catalog-js": () => import("./../../../src/pages/catalog.js" /* webpackChunkName: "component---src-pages-catalog-js" */),
  "component---src-pages-contacts-js": () => import("./../../../src/pages/contacts.js" /* webpackChunkName: "component---src-pages-contacts-js" */),
  "component---src-pages-delivery-js": () => import("./../../../src/pages/delivery.js" /* webpackChunkName: "component---src-pages-delivery-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-layout-js": () => import("./../../../src/pages/layout.js" /* webpackChunkName: "component---src-pages-layout-js" */),
  "component---src-pages-main-page-js": () => import("./../../../src/pages/main-page.js" /* webpackChunkName: "component---src-pages-main-page-js" */),
  "component---src-pages-modules-slider-js": () => import("./../../../src/pages/modules/slider.js" /* webpackChunkName: "component---src-pages-modules-slider-js" */),
  "component---src-pages-modules-swiper-js": () => import("./../../../src/pages/modules/swiper.js" /* webpackChunkName: "component---src-pages-modules-swiper-js" */),
  "component---src-pages-new-js": () => import("./../../../src/pages/new.js" /* webpackChunkName: "component---src-pages-new-js" */),
  "component---src-pages-service-service-js": () => import("./../../../src/pages/service/service.js" /* webpackChunkName: "component---src-pages-service-service-js" */),
  "component---src-templates-knives-list-js": () => import("./../../../src/templates/KnivesList.js" /* webpackChunkName: "component---src-templates-knives-list-js" */),
  "component---src-templates-product-detail-js": () => import("./../../../src/templates/ProductDetail.js" /* webpackChunkName: "component---src-templates-product-detail-js" */),
  "component---src-templates-service-pages-js": () => import("./../../../src/templates/ServicePages.js" /* webpackChunkName: "component---src-templates-service-pages-js" */)
}

